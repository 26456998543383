import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, CheckboxGroup, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/shingle-reseal/shingle-reseal-flow";
import { ShingleResealLayout } from "pages/shingle-reseal/shingle-reseal-layout";

import styles from "./shingle-reseal.module.scss";

export const WaiverSageSure = () => {
  const navigate = useNavigate();
  const { formNavigate, state } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);
  const [agreed, setAgreed] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !(
        state?.phone &&
        state?.firstName &&
        state?.lastName &&
        state?.emailAddress
      )
    ) {
      navigate("../policy-confirmation");
    }
  }, [
    navigate,
    state?.emailAddress,
    state?.firstName,
    state?.lastName,
    state?.phone,
  ]);

  const onAgreeClick = (val) => {
    setAgreed(val);
    setShowError(false);
  };

  const onSubmit = async () => {
    if (!agreed) {
      setShowError(true);
      return;
    }

    formNavigate("../waiver-liftlock");
  };

  return (
    <ShingleResealLayout
      step={4}
      heading="We need you to review and agree to SageSure’s waiver"
      buttonProps={{
        primaryOnClick: onSubmit,
      }}
    >
      <div className={styles.mainContent}>
        <div className={styles.terms}>
          {state?.termsSageSure?.split("\n").map((paragraph, i) => (
            <p key={i}>
              {i === 0 ? (
                <Button
                  icon="Print"
                  appearance="secondary"
                  size="xs"
                  className={styles.printButtonDS}
                  onClick={print}
                  data-testid="waiver-print"
                />
              ) : null}
              {paragraph}
            </p>
          ))}
        </div>
        <Span className={styles.printHideDS}>
          <CheckboxGroup
            isError={showError}
            errorMessage="Please agree to the waiver before submitting."
          >
            <CheckboxGroup.Checkbox
              onChange={onAgreeClick}
              isInvalid={showError}
            >
              I have read and agreed to the waiver above.*
            </CheckboxGroup.Checkbox>
          </CheckboxGroup>
        </Span>
      </div>
    </ShingleResealLayout>
  );
};
