import { useContext } from "react";

import { Heading, SpotIcon } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { ShingleResealLayout } from "pages/shingle-reseal/shingle-reseal-layout";
import { formatTwoLineAddress } from "utils";

import styles from "./shingle-reseal.module.scss";

export const PolicyConfirmation = () => {
  const { policy } = useContext(AuthAppContext);

  if (!policy.propertyAddress) {
    return null;
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(
    policy.propertyAddress,
    true
  );

  return (
    <ShingleResealLayout
      step={1}
      heading="Confirm your address"
      subheading="This service is available only for the property associated with your policy."
      buttonProps={{ primaryPath: "../contact-information" }}
    >
      <div className={styles.iconItem}>
        <SpotIcon name="Home" appearance="bold" />
        <div>
          <Heading size="sm">{lineOne}</Heading>
          <Heading size="sm">{lineTwo}</Heading>
          <div className={styles.subtext}>Policy number: {policy.policyId}</div>
        </div>
      </div>
    </ShingleResealLayout>
  );
};
